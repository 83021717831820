import React from 'react';
import shop from 'shopify-buy';
import { encode } from 'shopify-gid';
import cookie from 'js-cookie';

import algoliasearch from 'algoliasearch';

const algoliaClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_INDEX_API_KEY
);
const productSearchIndex = algoliaClient.initIndex(
  process.env.GATSBY_ALGOLIA_PRODUCT_INDEX
);

productSearchIndex.setSettings({ hitsPerPage: 100 });

export const CHECKOUT_KEY = 'eugenie_checkout';
const NEWSLETTER_KEY = 'eugenie_newsletter';

const shopify = shop.buildClient({
  domain: process.env.GATSBY_SHOPIFY_DOMAIN,
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_API_TOKEN,
});

const ShopContext = React.createContext();
export default ShopContext;

export class ShopContextProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // UI
      isCartOpen: false,
      isSearchOpen: false,

      // Customer
      customerEmail: undefined,
      customerName: undefined,
      customerAddress: undefined,
      customerToken: undefined,
      customerOrders: [],
      customerWishLists: [],

      // Checkout
      products: [],
      shop: {},
      checkout: { lineItems: [] },
      error: null,
      successMessage: null,
      successMessageColor: null,
      isHydratingCheckout: false,
      searchResults: [],
      hasSeenNewsletter: false,
    };
  }

  componentDidMount = () => {
    let productsPromise = shopify.product.fetchAll(250);
    let shopPromise = shopify.shop.fetchInfo();

    Promise.all([productsPromise, shopPromise]).then(response => {
      let products = response[0];
      let shop = response[1];

      this.setState({
        shop,
        products,
      });
    });
    this.hydrateCheckout();
    this.initCustomer();

    // newsletter
    const newsletterCookie = cookie.get(NEWSLETTER_KEY);

    this.setState({
      hasSeenNewsletter: !!newsletterCookie,
    });
  };

  hydrateCheckout = () => {
    const checkoutId = cookie.get(CHECKOUT_KEY);

    this.setState({ isHydratingCheckout: true });

    if (checkoutId) {
      shopify.checkout
        .fetch(checkoutId)
        .then(checkout => {
          if (checkout.completedAt) {
            shopify.checkout.create().then(newCheckout => {
              this.setState({
                newCheckout,
              });
            });
          } else {
            this.setState({
              checkout,
            });
          }
        })
        .catch(error => console.warn(error));
    } else {
      shopify.checkout.create().then(checkout => {
        this.setState({
          checkout,
        });
      });
    }
  };

  setCartOpen = boolean => {
    this.setState({
      isCartOpen: boolean,
    });
  };

  setSearchOpen = boolean => {
    this.setState({
      isSearchOpen: boolean,
    });
  };

  addVariantToCart = (variantId, quantity) => {
    const productVariant = encode('ProductVariant', variantId, {
      accessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_API_TOKEN,
    });

    const lineItemsToAdd = [{ variantId: productVariant, quantity: quantity }];
    const checkoutID = this.state.checkout.id;

    shopify.checkout
      .addLineItems(checkoutID, lineItemsToAdd)
      .then(updatedCheckout => {
        cookie.set(CHECKOUT_KEY, this.state.checkout.id, { expires: 25 });
        this.setState({
          checkout: updatedCheckout,
          error: null,
          isCartOpen: true,
        });
      })
      .catch(error => {
        this.setState({
          error,
          successMessage: null,
        });
      });
  };

  updateQuantityInCart = (lineItemID, quantity) => {
    const checkoutID = this.state.checkout.id;
    const lineItemsToUpdate = [{ id: lineItemID, quantity: Number(quantity) }];

    shopify.checkout
      .updateLineItems(checkoutID, lineItemsToUpdate)
      .then(updatedCheckout => {
        cookie.set(CHECKOUT_KEY, this.state.checkout.id, { expires: 25 });
        this.setState({
          checkout: updatedCheckout,
        });
      });
  };

  removeLineItemInCart = lineItemID => {
    const checkoutID = this.state.checkout.id;

    shopify.checkout
      .removeLineItems(checkoutID, [lineItemID])
      .then(updatedCheckout => {
        this.setState({
          checkout: updatedCheckout,
        });
      });
  };

  clearSuccessMessage = () => {
    this.setState({
      successMessageColor: null,
      successMessage: null,
    });
  };

  fetchProduct = productId => {
    const productVariant = encode('Product', productId, {
      accessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_API_TOKEN,
    });

    return shopify.product
      .fetch(productVariant)
      .then(product => product)
      .catch(error => {
        console.warn(error);
      });
  };

  searchStore = searchTerm => {
    productSearchIndex
      .search(searchTerm)
      .then(results => {
        this.setState({
          searchResults: results.hits,
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  setSeenNewsletter = () => {
    cookie.set(NEWSLETTER_KEY, true, { expires: 31 });

    this.setState({
      hasSeenNewsletter: true,
    });
  };

  setOrderGiftStatus = async ({ giftWrap, giftMessage }) => {
    const cartId = this.state.checkout.id;

    try {
      const currentCustomAttributes =
        this.state.checkout.customAttributes.filter(
          attribute =>
            attribute.key !== 'Gift Message' && attribute.key !== 'Gift Wrap'
        );
      const newCustomAttributes = {
        customAttributes: [
          { key: 'Gift Wrap', value: giftWrap ? 'Yes' : 'No' },
          { key: 'Gift Message', value: giftMessage || '' },
          ...currentCustomAttributes.map(({ key, value }) => ({
            key,
            value,
          })),
        ],
      };

      const newCart = await shopify.checkout.updateAttributes(
        cartId,
        newCustomAttributes
      );

      cookie.set(CHECKOUT_KEY, newCart.id, { expires: 25 });

      this.setState({
        checkout: newCart,
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  // Accounts

  initCustomer = async () => {
    console.log('initCustomer');
    const customerEmail = cookie.get('eugenie_customer_email');
    const customerToken = cookie.get('eugenie_customer_token');
    const customerName = cookie.get('eugenie_customer_firstName');
    const customerAddress = cookie.get('eugenie_customer_defaultAddress');

    const request = await fetch(`/api/wishlists?email=${customerEmail}`, {
      method: 'GET',
    });

    const { wishLists = [] } = await request.json();

    if (customerEmail && customerToken && customerName) {
      this.setState({
        customerEmail,
        customerToken,
        customerName,
        customerAddress: customerAddress
          ? JSON.parse(customerAddress)
          : undefined,
      });
    }

    if (wishLists.length) {
      this.setState({
        customerWishLists: wishLists,
      });
    }
  };

  setCustomerInState = () => {
    const customerEmail = cookie.get('eugenie_customer_email');
    const customerToken = cookie.get('eugenie_customer_token');
    const customerName = cookie.get('eugenie_customer_firstName');
    const customerAddress = cookie.get('eugenie_customer_defaultAddress');

    this.setState({
      customerEmail,
      customerToken,
      customerName,
      customerAddress: customerAddress
        ? JSON.parse(customerAddress)
        : undefined,
    });
  };

  setCustomerOrders = orders => {
    this.setState({
      customerOrders: orders,
    });
  };

  setCustomerWishLists = wishLists => {
    this.setState({
      customerWishLists: wishLists,
    });
  };

  logoutCustomer = () => {
    cookie.remove('eugenie_customer_email');
    cookie.remove('eugenie_customer_token');
    cookie.remove('eugenie_customer_firstName');
    cookie.remove('eugenie_customer_defaultAddress');

    this.setState({
      customerEmail: null,
      customerToken: null,
      customerName: null,
      customerAddress: null,
    });
  };

  render() {
    const value = {
      // values
      products: this.state.products,
      shop: this.state.shop,
      checkout: this.state.checkout,
      isCartOpen: this.state.isCartOpen,
      isSearchOpen: this.state.isSearchOpen,
      successMessage: this.state.successMessage,
      successMessageColor: this.state.successMessageColor,
      searchResults: this.state.searchResults,
      hasSeenNewsletter: this.state.hasSeenNewsletter,
      customerEmail: this.state.customerEmail,
      customerName: this.state.customerName,
      customerAddress: this.state.customerAddress,
      customerToken: this.state.customerToken,
      customerOrders: this.state.customerOrders,
      customerWishLists: this.state.customerWishLists,
      // methods
      hydrateCheckout: this.hydrateCheckout,
      setCartOpen: this.setCartOpen,
      setSearchOpen: this.setSearchOpen,
      addVariantToCart: this.addVariantToCart,
      updateQuantityInCart: this.updateQuantityInCart,
      removeLineItemInCart: this.removeLineItemInCart,
      setSanityProducts: this.setSanityProducts,
      clearSuccessMessage: this.clearSuccessMessage,
      fetchProduct: this.fetchProduct,
      searchStore: this.searchStore,
      setSeenNewsletter: this.setSeenNewsletter,
      setOrderGiftStatus: this.setOrderGiftStatus,
      initCustomer: this.initCustomer,
      setCustomerInState: this.setCustomerInState,
      setCustomerOrders: this.setCustomerOrders,
      setCustomerWishLists: this.setCustomerWishLists,
      logoutCustomer: this.logoutCustomer,
    };

    return (
      <ShopContext.Provider value={value}>
        {this.props.children}
      </ShopContext.Provider>
    );
  }
}
