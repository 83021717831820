import React from 'react';
import { defaultSerializers } from '@sanity/block-content-to-react';
import { Link } from 'gatsby';
import imageAspectRatioStyles from '../utils/imageAspectRatioStyles';

export default {
  types: {
    block: props => {
      const { style = 'normal' } = props.node;

      if (style === 'h1') {
        return <h1>{props.children}</h1>;
      } else if (style === 'h2') {
        return <h2>{props.children}</h2>;
      } else if (style === 'h3') {
        return <h3>{props.children}</h3>;
      }

      // Fall back to default handling
      return defaultSerializers.types.block(props);
    },
    image: props => (
      <div
        style={
          props.node.asset.metadata.dimensions
            ? imageAspectRatioStyles(
                props.node.asset.metadata.dimensions.aspectRatio
              )
            : null
        }
        className="db image"
      >
        <picture>
          <source
            srcSet={`${props.node.asset.url}?max-w=1200&auto=format&q=1005`}
            media="(min-width: 800px)"
          />
          <source
            srcSet={`${props.node.asset.url}?max-w=1000&auto=format&q=1005`}
            media="(min-width: 600px)"
          />
          <img
            alt={props.node.alt}
            src={`${props.node.asset.url}?max-w=800&auto=format`}
          />
        </picture>
      </div>
    ),
  },
  marks: {
    link: props => {
      if (props.mark.href[0] === '/') {
        return (
          <Link
            title={props.mark.title}
            target={props.mark.openInNewWindow ? '_blank' : null}
            to={props.mark.href}
          >
            {props.children}
          </Link>
        );
      }
      return (
        <a
          title={props.mark.title}
          target={props.mark.openInNewWindow ? '_blank' : null}
          href={props.mark.href}
        >
          {props.children}
        </a>
      );
    },
  },
};
